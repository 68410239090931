// @flow

// This file acts as a shim to communicate with the widgetApi at js/player-widget/components/WidgetApi/index.js

/*
This is a thin RPC layer providing a function:
var myWidget = window.Mixcloud.PlayerWidget(iframe);

after the myWidget.ready promise is resolved the API has been fetched from the widget
 and myWidget will be populated with methods and events.

When debugging this, it's useful to see the debug comments in this file and use the django template called debug_api.html

*/

import { isBrowser, isProduction } from 'shared/utils/consts';

import Deferred from './deferred';

type MessageData =
    | {
          type: 'getApi',
      }
    | {
          type: 'method',
          data: {
              methodId: number,
              methodName: string,
              args: Array<any>,
          },
      };

type WindowOnReceiveMessageCallback = {
    window: WindowProxy,
    callback: Function,
};

const ORIGIN = isProduction
    ? 'https://player-widget.mixcloud.com'
    : 'http://localhost:8092';

// This is changed when using debug_api.html:
const DEBUG = isBrowser && (window.testingPlayerApi || !isProduction);

class MixcloudPlayerWidgetApiRPC {
    iframe: WindowProxy;
    methodCounter = 0;
    methodResponses = {};
    eventHandlers = {};
    windowOnReceiveMessageCallbacks: WindowOnReceiveMessageCallback[] = [];

    external = {
        ready: new Deferred(),
        events: {},
    };

    constructor(iframe: HTMLIFrameElement) {
        this.iframe = iframe.contentWindow;

        // Add this widget window to the listeners (see onReceiveMessage)
        this.windowOnReceiveMessageCallbacks.push({
            window: this.iframe,
            callback: this.receiveMessage.bind(this),
        });

        // If the iframe is ready we can fetch the API
        this.send({ type: 'getApi' });

        // Listen in a cross-platform way
        if (window.addEventListener) {
            window.addEventListener('message', this.onReceiveMessage, false);
        } else {
            window.attachEvent('onmessage', this.onReceiveMessage);
        }
    }

    receiveMessage = (type: string, data: any) => {
        switch (type) {
            case 'ready':
                // if we're getting this event, the first attempt to get the api probably failed
                this.send({ type: 'getApi' });
                break;
            case 'api':
                this.buildApi(data);
                break;
            case 'event':
                if (this.eventHandlers[data.eventName]) {
                    this.eventHandlers[data.eventName].apply(
                        this.external,
                        data.args,
                    );
                }
                break;
            case 'methodResponse':
                if (this.methodResponses[data.methodId]) {
                    // Resolve the deferred promise
                    this.methodResponses[data.methodId].resolve(data.value);
                    delete this.methodResponses[data.methodId];
                }
                break;
        }
    };

    // Create a "deferred" promise that we can externally resolve once the response comes back from the iframe
    deferredMethodResponse = (
        methodId: number,
        methodName: string,
        ...args
    ) => {
        let resolver;
        const promise = new Promise((resolve) => {
            resolver = resolve;
            this.send({
                type: 'method',
                data: {
                    methodId: this.methodCounter,
                    methodName,
                    args,
                },
            });
        });

        // $FlowIgnore doesn't like attaching things to promises
        promise.resolve = resolver;

        return promise;
    };

    buildMethod =
        (methodName: string) =>
        (...args) => {
            this.methodCounter++;
            const methodId = this.methodCounter;

            this.methodResponses[this.methodCounter] =
                this.deferredMethodResponse(methodId, methodName, ...args);

            return this.methodResponses[this.methodCounter];
        };

    buildApi = ({
        methods,
        events,
    }: {
        methods: string[],
        events: string[],
    }) => {
        methods.forEach((methodName) => {
            // $FlowFixMe[prop-missing]
            this.external[methodName] = this.buildMethod(methodName);
        });

        events.forEach((eventName) => {
            this.eventHandlers[eventName] = window.Mixcloud.Callbacks();
            this.external.events[eventName] =
                this.eventHandlers[eventName].external;
        });

        this.external.ready.resolve(this.external);
    };

    onReceiveMessage = (event: MessageEvent) => {
        // DEBUG: Might need to remove this when using debug_api.html:
        if (
            !DEBUG &&
            ![ORIGIN, window.location.origin].includes(event.origin)
        ) {
            console.error(
                'Playerwidget received message from incorrect origin',
            );

            return;
        }

        let data;

        try {
            // $FlowIgnore we catch the error
            data = JSON.parse(event.data);
        } catch (err) {
            // Data is malformed or not relevant

            return;
        }

        if (data.mixcloud !== 'playerWidget') {
            // These messages are not relevant do not need to be actioned

            return;
        }

        // postMessage can be called from any window and there could be multiple
        // widgets on the page - this will call the callback for the appropriate
        // widget iframe

        this.windowOnReceiveMessageCallbacks.forEach(
            ({ callback, window: callbackWindow }) => {
                if (callbackWindow === event.source) {
                    callback(data.type, data.data);
                }
            },
        );
    };

    send = (data: MessageData) => {
        this.iframe.postMessage(JSON.stringify(data), DEBUG ? '*' : ORIGIN);
    };
}

((window) => {
    if (!window) {
        return;
    }

    const originalMixcloud = window.Mixcloud;

    window.Mixcloud = {
        noConflict: () => {
            window.Mixcloud = originalMixcloud;

            return window.Mixcloud;
        },
        Callbacks: () => {
            let callbacks: Function[] = [];

            return {
                apply: (context, args) => {
                    callbacks.forEach((callback) => {
                        callback.apply(context, args);
                    });
                },
                external: {
                    on: (callback) => {
                        callbacks.push(callback);
                    },
                    off: (callback) => {
                        callbacks = callbacks.filter((i) => i !== callback);
                    },
                },
            };
        },
        PlayerWidget: (iframe: HTMLIFrameElement) => {
            const api = new MixcloudPlayerWidgetApiRPC(iframe);

            // Only public methods
            return api.external;
        },
        ...window.Mixcloud,
    };
})(window);
