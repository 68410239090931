// @flow strict
// This is injected at bundle time by ViteJS
/* global __COMMIT_HASH__ */
/* global __MX_PLATFORM_NAME__ */

// This is used to identify the Browser as apposed to an SSR execution environment
export const isBrowser: boolean =
    typeof window !== 'undefined' && typeof window.scrollY === 'number';

// This is used to identify the Server as apposed to a Browser execution environment
export const isSSR: boolean = !isBrowser;

// Set to true when we are building a test build for Playwright, this is typically
// used for things like disabling ads when running Playwright tests
export const isPlaywrightTest: boolean =
    isBrowser && document.cookie.includes('playwright_test=1');

// This identifies production builds
export const isProduction: boolean =
    process.env.NODE_ENV === 'production' && !isPlaywrightTest;

export const isDevelopment: boolean = process.env.NODE_ENV !== 'production';

// This is used to identify when we are inside a JEST run and is used at run time not
// at build time unlike isPlaywrightTest
export const isTestRun: boolean = Boolean(process.env.JEST_WORKER_ID);

// Build Commit Hash
export const buildCommitHash: string =
    // ensure there are no new lines in the commit hash as it will be considered invalid
    // $FlowFixMe[cannot-resolve-name] - This is injected by Vite at build time
    __COMMIT_HASH__.replace(/\r?\n|\r/g, '') ?? 'unknown'; // eslint-disable-line no-undef

// $FlowFixMe[cannot-resolve-name] - This is injected by Vite at build time
export const platformName: string = __MX_PLATFORM_NAME__ ?? 'testing';
